import React from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import Discord from 'main/components/Footer/assets/discord.svg';
import Facebook from 'main/components/Footer/assets/facebook.svg';
import GitHub from 'main/components/Footer/assets/github.svg';
import LinkedIn from 'main/components/Footer/assets/linkedin.svg';
import Telegram from 'main/components/Footer/assets/telegram.svg';
import Twitter from 'main/components/Footer/assets/twitter.svg';
import { menus } from 'main/components/Footer/constants';

import useStyles, { BREAK_POINT } from './styles';

export const socials = [
    { label: 'Telegram', icon: Telegram, url: 'https://t.me/vulners' },
    { label: 'Facebook', icon: Facebook, url: 'https://www.facebook.com/vulners' },
    { label: 'LinkedIn', icon: LinkedIn, url: 'https://www.linkedin.com/company/vulners' },
    { label: 'Twitter', icon: Twitter, url: 'https://twitter.com/vulnerscom' },
    { label: 'GitHub', icon: GitHub, url: 'https://github.com/vulnerscom' },
    { label: 'Discord', icon: Discord, url: 'https://discord.com/invite/Emaa8UPnZd' }
];

interface Props {
    className?: string;
    classes?: {
        root: string;
    };
}

const FooterBar: React.FC<Props> = ({ classes, className }) => {
    const theme = useTheme();
    const { classes: internalClasses, cx } = useStyles();

    const breakpoint = useMediaQuery(theme.breakpoints.down(BREAK_POINT));

    return (
        <footer className={cx(internalClasses.root, classes?.root, className)}>
            <div className={internalClasses.section}>
                {menus.map(({ title, items }, index) => (
                    <div key={index} className={cx(internalClasses.column, internalClasses.menu)}>
                        <p className={internalClasses.title}>{title}</p>
                        <ul className={internalClasses.list}>
                            {items.map(({ label, url }, index) => (
                                <li key={index} className={internalClasses.item}>
                                    <a href={url}>{label}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className={internalClasses.subSection}>
                {breakpoint ? (
                    <p className={internalClasses.description}>
                        {
                            'Instantly receive the accurate information about new vulnerabilities and exploits emerging around the world'
                        }
                    </p>
                ) : (
                    <p className={internalClasses.copyright}>{`@${new Date().getFullYear()} Vulners Inc`}</p>
                )}

                <div className={internalClasses.socialButtons}>
                    {socials.map((s) => (
                        <Tooltip key={s.label} title={s.label} placement="top">
                            <a
                                href={s.url}
                                rel="noopener noreferrer nofollow"
                                className={internalClasses.socialButton}
                                target="_blank"
                            >
                                <s.icon />
                            </a>
                        </Tooltip>
                    ))}
                </div>

                {breakpoint ? (
                    <p className={internalClasses.copyright}>{`@${new Date().getFullYear()} Vulners Inc`}</p>
                ) : null}
            </div>
        </footer>
    );
};

export default FooterBar;
